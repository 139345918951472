/* Ehrenamtportal styles */

@import "../colors";
@import "../settings";

.kmta__tenant--ehrenamtportal {
    .ehrenamtportal_svgIcon--logo {
        width: 46px;
        height: 45px;
    }

    .kmta__tenantLogoAlt {
        display: inline-block;
        max-width: 294px;
    }

    .kmta__profileAddBillingBtn {
        display: none;
    }

    .kmta__headerBottom {
        background-color: transparent;
    }

    .kmta__menu > li {
        a {
            color: var(--nav-item-color);
        }
    }

    .button.hollow {
        background-color: transparent;
    }

    /* Messages */
    //.noty_theme__ehrenamtportal {
    //    &.noty_type__success {
    //        background-color: #0a2233;
    //        border-bottom-color: #0a2233;
    //    }
    //
    //    .noty_has_timeout .noty_progressbar {
    //        background-color: #000;
    //    }
    //}
}
